import { throttle } from '../lib/lodash.custom';

const Audience = class Audience {
	constructor(element, options) {
		this.defaults = { // Defaults
			duration: 425,
			easing: 'swing',
			callback() {}
		};

		this.element = element;
		this.options = $.extend({}, this.defaults, options);

		// Cached selectors
		this.elem = {
			$audienceSelector: $('#audience-selector'),
			$slider: $('#audience-selector__slider')
		};

		this.init();
	}

	init() {
		this.bindUIActions();
		this.animateSlider();
	}

	animateSlider(element) {
		element = (typeof element === 'undefined') ? '.audience-selector__tab.audience-selector__tab--is-active' : element;

		let position = $(element).parent().position();
		let width = $(element).parent().width();

		this.elem.$slider.css({
			'left': position.left,
			'width': width
		});
	}

	bindUIActions() {
		$(window).on('keydown', this.onKeyDown.bind(this));
		$(window).on('resize', throttle(this.onResize.bind(this), 100));

		$(this.element)
			.on('click', '.audience-selector__tab', this.onClick.bind(this))
			.find('.audience-selector__container').on('scroll', throttle(this.onScroll.bind(this), 100));
	}

	onClick(e, disableCallback = false) {
		e.preventDefault();

		let id = $(e.target).attr('href');
		let tabs = $(e.target).parent().siblings().find('.audience-selector__tab');

		// Enable all sibling tabs
		tabs.removeClass('audience-selector__tab--is-active').attr({ 'aria-selected': 'false', 'tabindex': '0' });

		// Disable the selected tab
		$(e.target).addClass('audience-selector__tab--is-active');
		$(e.target).attr({ 'aria-selected': 'true', 'tabindex': '-1' });

		this.animateSlider(e.target);

		// Find all sibling panels minus the selected panel w/ id and fade out
		$(`.audience__panel:not(${id})`).fadeOut(this.options.duration, this.options.easing).promise().done(() => {
			// Hide all panels
			$(`.audience__panel:not(${id})`).removeClass('audience__panel--is-visible').attr('aria-hidden', 'true');
			// Show selected panel
			$(`${id}`).fadeIn(this.options.duration, this.options.easing).addClass('audience__panel--is-visible').attr('aria-hidden', 'false');
		});

		// Callback
		if (typeof this.options.callback !== 'undefined' && typeof this.options.callback === 'function') {
			this.options.callback.call(this, e, disableCallback);
		}
	}	

	onKeyDown(e) {
		const code = (e.keyCode ? e.keyCode : e.which);

		let item;
		let i;

		if ($(document.activeElement).closest('#audience-selector').length > 0) {
			if (code === 37 || code === 39) {
				if (code === 37) { // Left arrow (37)
					item = $(document.activeElement).closest('.audience-selector__tab-item').prev();
				} else if (code === 39) { // Right arrow (39)
					item = $(document.activeElement).closest('.audience-selector__tab-item').next();
				}

				if (item.length > 0) {
					// Return to the first index or element within the list
					if (item.index() === ($('.audience-selector__tab-item', this.element).length)) {
						item = $('.audience-selector__tab-item', this.element).eq(0);
					}

					item.find('.audience-selector__tab').focus();
				} else {
					i = (item.index() === -1 && code === 37) ? ($('audience-selector__tab-item', this.element).length - 1) : 0;

					$('.audience-selector__tab-item', this.element).eq(i).find('.audience-selector__tab').focus();
				}
			}
		}
	}	

	onResize() {
		this.animateSlider('.audience-selector__tab.audience-selector__tab--is-active');
	}

	onScroll(e) {
		let $target = $(e.target);

		let width = $target.outerWidth();
		let scrollLeft = $target.scrollLeft();
		let scrollWidth = $target[0].scrollWidth;

		this.elem.$audienceSelector.toggleClass('audience-selector--has-shadow', ((Math.round(scrollWidth - width)) === (Math.floor(scrollLeft) || Math.round(scrollLeft))) ? false : true);
	}	
}

export default Audience;