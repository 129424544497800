import { mql } from '../lib/utils';
import { scrollElementsIntoView } from './scrollElementsIntoView';

const anteaterStory = {

	$window: $(window),

	elem: {
		$anteaterStory: ($('#anteater-story').length > 0) ? $('#anteater-story') : null
	},

	init() {
		if (this.elem.$anteaterStory === null) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.$window.on('load resize', () => {
			this.onLoad.call(this, mql.medium);
		});

		mql.medium.addListener(this.onLoad.bind(this));
	},

	onLoad(mq) {
		if (mq.matches) {
			scrollElementsIntoView('#anteater-story', 'anteater-story--is-visible', (this.elem.$anteaterStory.outerHeight(true) / 4));
		}
	}

};

export { anteaterStory };