import { plugin } from './lib/plugin';
import { ready, mql } from './lib/utils';
import { anteaterExperience } from './modules/anteater-experience';
import { anteaterStory } from './modules/anteater-story';
import Audience from './modules/audience';
import { deadlines } from './modules/deadlines';
import { locationFeature } from './modules/location-feature';
import { programsFeature } from './modules/programs-feature';
import { studentStory } from './modules/student-story';

const home = {

	elem: {
		$admissions: $('#main.admissions'),
		$audience: $('#audience')
	},

	init() {
		// Init plugins
		plugin('Audience', Audience);

		anteaterExperience.init();
		anteaterStory.init();
		deadlines.init();
		locationFeature.init();
		programsFeature.init();
		studentStory.init();

		this.bindUIActions();
	},

	bindUIActions() {
		// Plugins
		this.elem.$audience.Audience({
			callback(e) {
				// Hide/show all .button(s) and .callout-cards__list elements that match the selected program criteria
				if ($('.fullwidth-callout').length === 0 && $('.callout-cards').length === 0) {
					return;
				}

				$(`.button[data-program="${$(e.target).data('program-id')}"]`).removeClass('hide').attr('aria-hidden', 'false');
				$(`.button[data-program]:not([data-program="${$(e.target).data('program-id')}"])`).addClass('hide').attr('aria-hidden', 'true');

				$(`.callout-cards__list[data-program="${$(e.target).data('program-id')}"]`).removeClass('hide').attr('aria-hidden', 'false');
				$(`.callout-cards__list[data-program]:not([data-program="${$(e.target).data('program-id')}"])`).addClass('hide').attr('aria-hidden', 'true');			
			}
		});

		this.elem.$admissions.on('mousemove', this.onMouseover.bind(this));
	},

	onMouseover(e) {
		// Anteater Story
		this.parallax(e, '.anteater-story__image', -60);
		// Student Story
		this.parallax(e, '.student-story', -30);
		// Anteater Experience
		this.parallax(e, '.anteater-experience__image', -60);
	},

	parallax(e, target, movement) {
		const $this = $('#main');
		const isMobile = !mql.medium.matches;

		let relX = e.pageX - $this.offset().left;
		let relY = e.pageY - $this.offset().top;

		if (isMobile) {
			gsap.to(target, {
				x: 0,
				y: 0,
				ease: 'none',
				clearProps: 'all'
			});
		} else {
			gsap.to(target, 1, {
				x: (relX - $this.width() / 2) / $this.width() * movement,
				y: (relY - $this.height() / 2) / $this.height() * movement,
				ease: 'power2.out',
				clearProps: 'auto'
			});				
		}
	}

};

ready(function () {
	home.init();
});