import { throttle } from '../lib/lodash.custom';
import { mql } from '../lib/utils';

class Sticky {
	constructor(element, options) {
		this.defaults = {
			position: 0,
			start: 0,
			stop: 0,
			vAlign() {
				return 0;
			}
		};

		this.$window = $(window);

		this.element = element;
		this.options = $.extend({}, this.defaults, options);

		this.init();
	}

	init() {
		this.bindUIActions();
	}

	bindUIActions() {
		this.$window.on('scroll resize', throttle(this.onScroll.bind(this), 10));
	}

	onScroll() {
		if (!mql.medium.matches) {
			if ($(this.element).attr('style') !== '') {
				$(this.element).attr('style', '');
			}

			return;
		}

		if (this.$window.scrollTop() > this.options.position) {
			$(this.element).css({
				position: 'absolute',
				top: (this.options.stop - this.options.vAlign())
			});

			return;
		}

		$(this.element).css({
			position: 'fixed',
			top: ((this.options.start > 0) ? this.options.start : 0)
		});
	}
}

export default Sticky;