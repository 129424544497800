import { mql } from '../lib/utils';
import { plugin } from '../lib/plugin';
import Sticky from './sticky';

const studentStory = {

	$window: $(window),

	elem: {
		$anteaterExperience: $('#anteater-experience'),
		$anteaterStory: $('#anteater-story'),
		$globalHeader: $('#global-header'),
		$overlay: $('#overlay'),
		$studentStory: $('#student-story')
	},

	init() {
		// Plugin(s)
		plugin('Sticky', Sticky);

		this.bindUIActions();
		this.sticky(mql.medium);
	},

	bindUIActions() {
		window.matchMedia(`(min-height: ${this.elem.$anteaterExperience.outerHeight(true)}px)`).addListener((mq) => {
			if (mq.matches) {
				this.sticky.call(this, mq);
			} else {
				this.sticky.call(this, mql.medium)
			}
		});

		mql.medium.addListener(this.sticky.bind(this));

		this.elem.$studentStory.on('mouseout', '.play-button', (e) => $(e.target).blur());
	},	

	sticky(mq) {
		let anteaterExperienceHeight = this.elem.$anteaterExperience.outerHeight(true);
		let anteaterStoryHeight = this.elem.$anteaterStory.outerHeight(true);
		let globalHeaderHeight = this.elem.$globalHeader.outerHeight(true);
		let positionTop = 302; // Default

		let start = positionTop;
		let stop = (globalHeaderHeight + anteaterStoryHeight) + (positionTop - globalHeaderHeight);
		let position = stop - start;

		stop = (window.matchMedia(`(min-height: ${anteaterExperienceHeight}px)`).matches) ? (stop + 100) : stop;
		position = stop - start;

		let options = {
			position,
			start, 
			stop,
			vAlign() {
				return $('#global-header').outerHeight(true);
			}
		};

		if (mq.matches) {
			this.elem.$studentStory.removeData().attr('style', '');
			this.elem.$studentStory.Sticky(options);
		} else {
			this.elem.$studentStory.removeData().attr('style', '');
		}
	}

};

export { studentStory };