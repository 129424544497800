import { throttle } from '../lib/lodash.custom';

/**
 * @name scrollElementsIntoView
 * @param {Object[]} elements // jQuery object 
 * @param {string} style 
 * @param {number} offset
 * @returns {string} // style (class)
 */
const scrollElementsIntoView = function (elements, style, offset = 0) {
	let $elements = $(elements);
	let $window = $(window);

	const checkIfInView = function () {
		let windowHeight = $(window).height();
		let windowTopPosition = $(window).scrollTop();
		let windowBottomPosition = (windowTopPosition + windowHeight);

		$.each($elements, function () {
			let $element = $(this);

			let elementHeight = $element.outerHeight(true);
			let elementTopPosition = $element.offset().top + offset;
			let elementBottomPosition = (elementTopPosition + elementHeight) - offset;

			return ((elementBottomPosition >= windowTopPosition) && (elementTopPosition <= windowBottomPosition)) ? $element.addClass(style) : null;
		});
	};

	$window.on('scroll resize', throttle(() => {
		checkIfInView();
	}, 100));

	$window.trigger('scroll');
};

export { scrollElementsIntoView };