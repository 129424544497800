import { mql, scrollIntoView } from '../lib/utils';
import { scrollElementsIntoView } from './scrollElementsIntoView';

const locationFeature = {

	$window: $(window),

	elem: {
		$locationFeature: ($('#location-feature').length) ? $('#location-feature') : null
	},

	init() {
		if (this.elem.$locationFeature === null) {
			return;
		}

		this.bindUIActions();
		this.onResize(mql.medium);
		this.onScroll(mql.medium);
	},

	bindUIActions() {
		this.$window.on('load', () => {
			this.onLoad.call(this, mql.medium);
		});

		mql.medium.addListener(this.onLoad.bind(this));
		mql.medium.addListener(this.onResize.bind(this));
	},

	onLoad(mq) {
		if (mq.matches) {
			scrollElementsIntoView('#location-feature', 'location-feature--is-visible', (this.elem.$locationFeature.outerHeight(true) / 4));
		}		
	},

	onResize(mq) {
		const $svg = this.elem.$locationFeature.find('.location-feature__svg');
		const $circle = $svg.find('circle');

		$svg.attr('height', () => (mq.matches) ? '654' : '224');
		$svg.attr('width', () => (mq.matches) ? '654' : '224');
		
		$circle.attr('cx', () => (mq.matches) ? '327' : '112');
		$circle.attr('cy', () => (mq.matches) ? '327' : '112');
		$circle.attr('r', () => (mq.matches) ? '307' : '102');
		$circle.attr('stroke-width', () => (mq.matches) ? '40' : '20');
	},

	onScroll(mq) {
		if (this.elem.$locationFeature.hasClass('location-feature--has-video') === false) {
			return;
		}

		if (mq.matches) {
			const offset = (this.elem.$locationFeature.outerHeight(true) / 2);

			scrollIntoView.apply(this, [this.elem.$locationFeature, offset, () => {
				const locationFeature = document.querySelector('#location-feature');
				const videoContainer = locationFeature.querySelector('.video-container');
				const media = videoContainer.querySelector('video');

				if (videoContainer.dataset.playback === 'play') {
					return;
				}

				if (media.paused) {
					media.play();
					videoContainer.dataset.playback = 'play';
				}
			}]);
		}
	}

};

export { locationFeature };