import { mql} from '../lib/utils';
import { scrollElementsIntoView } from './scrollElementsIntoView';

const anteaterExperience = {

	$window: $(window),

	elem: {
		$anteaterExperience: ($('#anteater-experience').length > 0) ? $('#anteater-experience') : null
	},

	init() {
		if (this.elem.$anteaterExperience === null) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.$window.on('load', () => {
			this.onLoad.call(this, mql.medium);
		});

		mql.medium.addListener(this.onLoad.bind(this));
	},

	onLoad(mq) {
		if (mq.matches) {
			scrollElementsIntoView('#anteater-experience', 'anteater-experience--is-visible', (this.elem.$anteaterExperience.outerHeight(true) / 4));
		}
	}

};

export { anteaterExperience };