import { mql} from '../lib/utils';
import { scrollElementsIntoView } from './scrollElementsIntoView';

const deadlines = {

	$window: $(window),

	elem: {
		$deadlines: ($('#deadlines').length > 0) ? $('#deadlines') : null
	},

	init() {
		if (this.elem.$deadlines === null) {
			return;
		}

		this.bindUIActions();
	},

	bindUIActions() {
		this.$window.on('load', () => {
			this.onLoad.call(this, mql.medium);
		});

		mql.medium.addListener(this.onLoad.bind(this));
	},

	onLoad(mq) {
		if (mq.matches) {
			scrollElementsIntoView('#deadlines', 'deadlines--is-visible', (this.elem.$deadlines.outerHeight(true) / 4));
		}
	}

};

export { deadlines };